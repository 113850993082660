import { Button } from "@mui/material"
import Link from "next/link"
import { useTranslation } from "react-i18next"

import { useUser } from "./useUser"

const PREFIX = "Header"
const classes = {
  root: `${PREFIX}-root`,
  signIn: `${PREFIX}-signIn`,
  pageTitle: `${PREFIX}-pageTitle`,
}

const SignInButton: React.FC = () => {
  const { user } = useUser({})
  const { t } = useTranslation()

  return (
    <Link href="/profile" passHref>
      <Button
        size="small"
        sx={{ borderColor: "accentTwo.main", color: "accentTwo.main" }}
        variant="outlined"
        className={classes.signIn}
        data-cy="header-profile-button"
      >
        {user === undefined ? t("Sign In") : t("Profile")}
      </Button>
    </Link>
  )
}

export default SignInButton
